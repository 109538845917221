/* AllOrders.module.css */
.titleTypography {
  color: white;
  padding: 3px 0;
  text-align: center;
}

.ordersColumn {
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  border: 1px solid #c4c4c4;
  align-items: stretch;
  justify-content: stretch;
  flex: 1;
}

.ordersHeader {
  color: black;
  padding: 3px 0;
  border-radius: 5px;
}

.ordersContainer {
  padding: 0.5rem
}

.orderItemsContainer{
  padding: 0.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

}
.orderOptionsContainer{
  display: flex;
  justify-content: space-between;
  align-items: center;

}
.orderCard {
  margin-bottom: 8px;
  margin-top: 8px;
  border: 1px solid #b3b3b3;
  border-radius: 8px;
  padding: 5px;
  min-height: fit-content;
  cursor: grab;
  flex-shrink: 0;
}

.orderCardDragging {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  background-color: #f4ffc1;
}

.orderDetails {
  background: #28c76f;
  border-radius: 0.25rem;
  margin-bottom: 12px;
  padding: 8px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
}

.timeContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.time {
  display: flex;
  align-items: center;

}

.commentContainer{
  text-align: center;
}

.placed {
  background-color: #01A4E5;
}

.inProgress {
  background-color: #4CB060;
}

.completed {
  background-color: #D28400;
}

::-webkit-scrollbar{
  width: 12px
}

::-webkit-scrollbar-thumb{
  background-color: rgba(0,0,0,0.2);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover{
  background-color: rgba(0,0,0,0.3);
}

::-webkit-scrollbar-thumb:active{
  background-color: rgba(0,0,0,0.4);
}

::-webkit-scrollbar-track{
  background-color: rgba(0,0,0,0.1);
}

::-webkit-scrollbar-track:hover{
  background-color: rgba(0,0,0,0.2);
}

::-webkit-scrollbar-track:active{
  background-color: rgba(0,0,0,0.3);
}
