/* Login.module.css */
.loginRoot {
  height: 100vh;
}

.loginBackgroundImage {
  background-image: url('https://www.bdtask.com/sp/cloud-kitchen/img/cloud-kitchen-management-system/cloud-kitchen-pos-system.webp');
  background-repeat: no-repeat;
  background-color: #228B22;
  background-size: contain;
  background-position: center;
}

.loginContentBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  row-gap: 0.5rem;
}

.loginLogo {
  height: 100px;
  width: 200px;
  max-height: 333px;
  max-width: 350px;
}

.loginForm {
  width: 80%;
}

.loginButton {
  padding: 8px 20px;
  font-size: 16px;
  background-color: #228B22;
  margin-right: 1px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.loginButton:hover {
  background-color: #228B22;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
}
  